<script setup lang="ts">
import { ref } from 'vue'
import type { LngLatLike } from 'mapbox-gl'
import { useDisplay, useTheme } from 'vuetify'

// @ts-expect-error кривая либа
import { MapboxGeocoder, MapboxGeolocateControl, MapboxMap, MapboxMarker, MapboxNavigationControl } from '@studiometa/vue-mapbox-gl'

interface FoundLocation {
  center: [number, number]
  place_name: string
  place_type: string
}

const { smAndDown } = useDisplay()

const token = useRuntimeConfig().public.mapboxToken

/* TODO
     map.addControl(new FullscreenControl(), 'bottom-right')

// const countries = 'de' // TODO: need?
*/

const language = 'en-EN'
const placeholder = 'City 🏰'

const zoom = ref(14)
const lng = ref(13.360170102765077)
const lat = ref(52.50780031063258)
const mapCenter = ref<LngLatLike>([lng.value, lat.value])
// TODO:
const markers = [mapCenter.value, [13.360610, 52.505995]]

const prefix = 'mapbox://styles/mapbox'
const mapStyle = computed(() => useTheme().global.name.value === 'dark'
  ? `${prefix}/dark-v11`
  : `${prefix}/streets-v12`)

const zoomControls = computed(() => smAndDown.value ? 'bottom-right' : 'top-left')

const heightmap = computed<string>(() => {
  if (smAndDown.value)
    return '75dvh'
  else return '358px'
})

onMounted(() => {
  // hackMap()
})

function mapCreated() {
  console.log('Map created!')
}

function findMe(event: GeolocationPosition) {
  const coords: GeolocationCoordinates = event.coords
  console.info(`Find me triggered: latitude=${coords.latitude}, longitude=${coords.longitude}`)
}

function searchByText(event: any) { // GeolocationPosition
  console.info(`User input: ${event.query}`)
}

function searchByTextIsSelected(event: any) {
  const location: FoundLocation = event.result
  const center: [number, number] = location.center
  const longitude = center[0]
  const latitude = center[1]
  mapCenter.value = [longitude, latitude]
  console.log(`Found location by text is selected: latitude=${latitude}, longitude=${longitude}, place_name='${location.place_name}'`)
  // console.dir(location)
}

function mapClicked(event: any) {
  const coords: { lng: number, lat: number } = event.lngLat
  console.log(`Map clicked:  latitude=${coords.lat}, longitude=${coords.lat}`)
}
</script>

<template>
  <section class="wrapper mt-6">
    <v-no-ssr>
      <MapboxMap
        class="rounded-lg" :center="mapCenter"
        :map-style="mapStyle" :zoom="zoom" :access-token="token"
        @mb-created="mapCreated" @mb-click="mapClicked"
      >
        <MapboxMarker v-for="coord in markers" :key="coord" :lng-lat="coord" />

        <MapboxNavigationControl :position="zoomControls" :show-compass="smAndDown" />

        <!--  :fit-bounds-options="{ zoom: 12 }"  -->
        <MapboxGeolocateControl
          position="bottom-left"
          :position-options="{ enableHighAccuracy: true, timeout: 6000 }"
          :track-user-location="smAndDown"
          @mb-geolocate="findMe"
        />

        <!-- :countries="countries" -->
        <MapboxGeocoder
          :language="language" :placeholder="placeholder"
          :access-token="token"
          @mb-result="searchByTextIsSelected"
          @mb-loading="searchByText"
        />
      </MapboxMap>
    </v-no-ssr>
  </section>
</template>

<style lang="scss">
@import 'assets/map/mapbox-gl'; // 'mapbox-gl/dist/mapbox-gl.scss'
@import 'assets/map/mapbox-gl-geocoder'; // '@mapbox/mapbox-gl-geocoder/lib/mapbox-gl-geocoder.scss';

.wrapper {
  height: v-bind(heightmap);
}

.mapboxgl-map {
  height: 100%;
}

// TODO
.mapboxgl-marker {
  filter: invert(0.1) sepia(1) saturate(5) hue-rotate(190deg);
}

/* TODO: https://github.com/NASA-IMPACT/veda-ui/blob/cb2c25f8e53b904b0977800aed98bed753d61c5b/app/scripts/components/common/mapbox/mapbox-style-override.js#L257
         https://github.com/wJoenn/rentart/blob/0b702b43e35bf3427874d4f72c29030166b63e63/app/assets/stylesheets/components/_address_autocomplete.scss#L22 */
.mapboxgl-ctrl-geocoder--input {
  color: rgb(var(--v-text-primary)) !important;
}

.mapboxgl-ctrl-group {
  button {
    background-color: rgb(var(--v-theme-surface));

    .mapboxgl-ctrl-icon {
      filter: invert(0.5) sepia(1) saturate(5) hue-rotate(190deg);

      svg .mapboxgl-ctrl-compass {
        filter: invert(0.5) sepia(1) saturate(5) hue-rotate(20deg) !important;
      }
    }
  }

  &:hover {
    background-color: rgb(var(--v-theme-inputborder));
  }
}
</style>
