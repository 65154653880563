import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Address } from '~/api/auth/models/address'
import { AddressControllerApi } from '~/api/auth/controllers/address-controller-api'
import { withAxiosToken } from '~/composables/utils'

export const useAddressStore = defineStore('address', () => {
  const myAddress = ref<Address | null>()
  const addressFetched = ref<boolean>(false)

  async function fetchMyAddress() {
    if (myAddress.value) {
      console.info(`${myAddress.value.confirmed ? '' : 'NOT'} Confirmed address already fetched. City=${myAddress.value.city}`)
      return
    }
    const addressController = new AddressControllerApi(await withAxiosToken())
    try {
      myAddress.value = (await addressController.getMyAddress()).data
    }
    finally {
      addressFetched.value = true
    }
  }

  function update(newAddress: Address | null) {
    myAddress.value = newAddress
    // console.log(`🌎 Country is updated in store`)
  }

  return { myAddress, fetchMyAddress, update, addressFetched }
})
